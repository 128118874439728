import React, { useState } from "react";
import PText from "../ptext/ptext.component";
import './accordion-menu.styles.scss';

const AccordionMenu = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleItemClick = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="accordion-menu">
      {items.map((item, index) => (
        <div
          key={index}
          className={`accordion-menu__item ${
            activeIndex === index ? "active" : ""
          }`}
          onClick={() => handleItemClick(index)}
        >
          <div className="accordion-menu__item-header">
            <h3 className="accordion-menu__item-title">{item.title}</h3>
            <div
              className={`accordion-menu__item-icon ${
                activeIndex === index ? "active" : ""
              }`}
            >
              <span className="accordion-menu__item-icon__bar"></span>
              <span className="accordion-menu__item-icon__bar"></span>
            </div>
          </div>
          <div className="accordion-menu__item-content">
            <PText>{item.content}</PText>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccordionMenu;
