import { useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Outlet } from "react-router-dom";
import { useForm, ValidationError } from '@formspree/react';
import ImageAccordion from "../../components/image-accordion/image-accordion.component";
import swal from "sweetalert";
import test0 from '../../img/test/download-test.jpg'
import pdfTest from '../../pdf/EO-MX_MERIDA_22-24-MAR_2023.pdf'
import test1 from '../../img/test/test1.jpg'
import test2 from '../../img/test/test2.jpg'
import test3 from '../../img/test/test3.webp'
import test4 from '../../img/test/test4.webp'
import test5 from '../../img/test/test5.jpg'
import test6 from '../../img/test/test6.webp'
import test7 from '../../img/test/test7.jpg'
import test8 from '../../img/test/test8.jpg'
import test9 from '../../img/test/test9.jpg'
//import test10 from '../../img/test/test10.png'
//import test11 from '../../img/test/test11.png'
import './contact.styles.scss'

const categories = [
    {
        id: 0,
        title: 'Descargar',
        image: `${test0}`,
        pdf: `${pdfTest}`,
    },
    {
        id: 1,
        title: 'Descargar',
        image: `${test1}`,
    },
    {
        id: 2,
        title: 'Descargar',
        image: `${test2}`,
    },
    {
        id: 3,
        title: 'Descargar',
        image: `${test3}`,
    },
    {
        id: 4,
        title: 'Descargar',
        image: `${test4}`,
    },
    {
        id: 5,
        title: 'Descargar',
        image: `${test5}`,
    },
    {
        id: 6,
        title: 'Descargar',
        image: `${test6}`,
    },
    {
        id: 7,
        title: 'Descargar',
        image: `${test7}`,
    },
    {
        id: 8,
        title: 'Descargar',
        image: `${test8}`,
    },
    {
        id: 9,
        title: 'Descargar',
        image: `${test9}`,
    },
    /*{
        id: 10,
        title: 'Descargar',
        image: `${test10}`,
    },
    {
        id: 11,
        title: 'Descargar',
        image: `${test11}`,
    },*/
];

const defaultFormFields = {
    name: '',
    email: '',
    subject: '',
    message: ''
}

const Contact = () => {
    const [formFields, setFormFields] = useState(defaultFormFields);
    const [state, handleSubmit] = useForm("mnqybodb");
    const { name, email, subject, message } = formFields;

    const resetFormFields = () => {
        swal({ icon: 'success', timer: 1000 });
        setTimeout(function () {
            setFormFields(defaultFormFields);
        }, 500);
    }
    
    const handleEvent = (event) => {
        const { name, value } = event.target;

        setFormFields({ ...formFields, [name]: value })

    }

    return (
        <section id="contact">
            <div className="slider" style={{ paddingTop: '5%'}}>
                <div style={{ textAlign: 'center', fontSize: '2.5rem', paddingBottom: '1.2rem' }}>
                    Consulting / Research and Reportage Blog
                </div>
                <ImageAccordion images={categories} />
            </div>
            <div className="contact-container">
                <div name='contact' className="left-container">
                    <Slide direction="left" >
                        <div className="contact-info-container">
                            <Fade cascade delay={1000} damping={0.5}>
                                <h1 className="contact-title">Contáctanos</h1>
                                <h2 >DISRUPTIVA</h2>
                                <div className="phones-container">
                                    <span className="fi fi-mx" />
                                    <h2>+52 999 489 0068</h2>
                                </div>
                                <div className="phones-container">
                                    <span className="fi fi-us" />
                                    <h2>+1 213 784 0059</h2>
                                </div>
                                <h3>contacto@disruptivaconsulting.com</h3>
                            </Fade>
                        </div>
                    </Slide>
                </div>
                <div name='contact' className="right-container">
                    <Slide direction="right" >
                        <form onSubmit={handleSubmit} className="form-container">
                            <input type='text' required placeholder="Nombre" className="input-form-container" name='name' onChange={handleEvent} value={name} />
                            <input type='email' required placeholder="Email" className="input-form-container" name='email' onChange={handleEvent} value={email} />
                            <ValidationError
                                prefix="Email"
                                field="email"
                                errors={state.errors}
                            />
                            <input type='text' required placeholder="Motivo" className="input-form-container" name='subject' onChange={handleEvent} value={subject} />
                            <textarea required placeholder="Mensaje" className="input-textarea-container" name='message' onChange={handleEvent} value={message} />
                            <button type="submit" className="contact-buttom-form" onClick={resetFormFields}>Enviar</button>
                        </form>
                    </Slide>
                </div>
            </div>
            <Outlet />
        </section>
    )
};

export default Contact;