import { Routes, Route, Outlet } from 'react-router-dom';
import './App.css';
import Navigation from './routes/navigation/navigation.component';
import Home from './routes/home/home.component';
import About from './routes/about/about.component';
import Contact from './routes/contact/contact.component';
import Services from './routes/services/services.component';
import { Fragment } from 'react';
import Privacity from './routes/privacity/privacity.component';
function App() {
  return (
    <Fragment>
      <Routes>
        <Route path='/' element={<Navigation />}>
          <Route index element={<Home />} />
          <Route path='about' element={<About />} />
          <Route path='contact' element={<Contact />} />
          <Route path='services' element={<Services />} />
          <Route path='privacity' element={<Privacity />} />
        </Route>
      </Routes>
      <Outlet />
    </Fragment>
  );
}

export default App;