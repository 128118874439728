import { Fragment, useState } from "react";
import { Outlet } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Footer from "../../components/footer/footer.component";
import { FaBars, FaTimes } from 'react-icons/fa'
import logo from '../../img/logo-consulting-mx.png'

import './navigation.styles.scss';
import { NavLink } from "react-router-dom";

const Navigation = () => {
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    return (
        <Fragment>
            <div className="navigation-container">
                <NavLink to='/' className="logo-container" >
                    <img src={logo} alt='logo' className="logo" />
                </NavLink>
                <div className="mobile-menu-icon"
                    onClick={() => setIsOpenMenu(!isOpenMenu)}
                    role='button'
                    onKeyDown={() => setIsOpenMenu}
                    tabIndex={0}>
                    <FaBars color="white" />
                </div>
                <div className={isOpenMenu ? 'links-container' : 'hide-item'} >
                    <div className="mobile-menu-close"
                        onClick={() => setIsOpenMenu(!isOpenMenu)}
                        role='button'
                        onKeyDown={() => setIsOpenMenu}
                        tabIndex={0}>
                        <FaTimes />
                    </div>
                    <HashLink className="navlink" to='#home' smooth>Home</HashLink>
                    <HashLink className="navlink" to='#about' smooth>About</HashLink>
                    <HashLink className="navlink" to='#services' smooth>Servicios</HashLink>
                    <HashLink className="navlink" to='#contact' smooth>Contact</HashLink>
                </div>
            </div>
            <Outlet />
            <Footer />
        </Fragment>
    )
}

export default Navigation;