import { useState } from 'react';
import './image-accordion.styles.scss'

const ImageAccordion = ({ images }) => {
    const [activeIndex, setActiveIndex] = useState(null);

    function handleClick(index) {
        setActiveIndex(index === activeIndex ? -1 : index);
        //console.log(activeIndex);
    }

    return (
        <div className="image-accordion">
            {images.map((image, index) => (
                <div key={index} className={`image-accordion__item ${index === activeIndex ? 'active' : ''}`}>
                    <a href={image.pdf} download>
                        <img src={image.image} alt={image.title} onClick={() => handleClick(index)} />
                    </a>
                    <div className="image-accordion__item-content">
                        <div className="image-accordion__item-content__title">{image.title}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ImageAccordion;