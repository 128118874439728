import PText from "../../components/ptext/ptext.component";
import './privacity.styles.scss'

const Privacity = () => {
    return (
        <div className="privacity-container">
            <PText>
                <h1>Aviso de Privacidad</h1>
                <div className="privacity-info-container">
                    <p><span>DSN SD SAS DE CV.</span> es una empresa legalmente constituida de conformidad con las leyes
                        mexicanas con nombre comercial DISRUPTIVA CONSULTING MEXICO. Conforme a lo
                        establecido en la Ley Federal de Protección de Datos en Posesión de Particulares , ponemos
                        a disposición nuestro Aviso de Privacidad
                    </p>
                    <p><span>DSN SD SAS DE CV.</span> se hace responsable del uso de los datos personales que le sean
                        entregados por parte de los clientes y/o proveedores y del público en general, mismos que
                        serán utilizados para beneficio de los mismos y la información será tratada de forma
                        estrictamente confidencial.
                    </p>
                    <p><span>DSN SD SAS DE CV.</span> se compromete en todo momento al obtener, usar,
                        almacenar y en general al tratar datos personales conduciéndose conforme a la Ley Federal
                        de Protección de Datos en Posesión de Particulares y su reglamento, observando los
                        principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad
                        y responsabilidad.
                    </p>
                    <p><span>DSN SD SAS DE CV.</span> se compromete al manejo de estos datos personales con
                        privacidad, respondiendo a la confianza depositada por sus clientes, proveedores y cualquier
                        persona vinculada con la empresa
                    </p>

                    <h3>Fundamento legal para el tratamiento de los datos personales:</h3>
                    <p>
                        <span>DSN SD SAS DE CV.</span> trata de datos personales antes señalados
                        con fundamento en el artículo 11,12,15,16,19 y 26 fracción I y VI de la Ley Federal de
                        Protección de Datos Personales en posesión de los particulares publicada en fecha del 5 del
                        julio del año 2010 en el Diario Oficial de la Federación.
                    </p>
                    <h3>Terminología:</h3>
                    <p>
                        <span>Derechos ARCO:</span> estos son los derechos de Acceso, Rectificación, Cancelación y Oposición
                        que cada persona titular tiene sobre sus datos personales recabados en este caso por
                        DISRUPTIVA INTERNATIONAL CONSULTING.
                    </p>
                    <ul>
                        <li>Derecho de ACCESO: es el que tiene su titular para conocer sobre datos personales
                            relacionados a su persona que obren en poder del responsable y del tratamiento que
                            se le da a los mismos.
                        </li>
                        <li>Derecho de RECTIFICACIÓN: es el que tiene su titular a que sus datos personales
                            sean rectificados cuando estos sean erróneos o incompletos.
                        </li>
                        <li>Derecho de CANCELACIÓN: es el derecho que tiene cada titular a solicitar en
                            cualquier momento que se supriman sus datos personales. La cancelación se realizará
                            una vez que transcurra el bloqueo, entendiendo como bloqueo la identificación y
                            conservación de los datos personales, una vez cumplida la finalidad para la cual
                            fueron recabados, con el propósito de determinar las posibles responsabilidades en
                            relación con su tratamiento, hasta el plazo de prescripción legal o contractual de éstas.
                            Durante dicho período, los datos personales no podrán ser objeto de tratamiento y
                            transcurrido éste, se procederá a su cancelación en la base de datos que
                            corresponde
                        </li>
                        <li>Derecho de OPOSICIÓN: el titular tiene derecho a solicitar, siempre que tenga causa
                            legítima, que el responsable deje de tratar sus datos personales.</li>
                    </ul>
                    <p>
                        <span>Datos personales:</span> Cualquier información concerniente a una persona física identificada o
                        identificable
                    </p>
                    <h3>¿Quién dará tratamiento a tus datos personales?</h3>
                    <p>Los datos personales proporcionados por usted serán utilizados por DSN SMART
                        DEVELOPMENTS S.A.S. DE C.V.
                    </p>
                    <h3>¿Con qué finalidad <span>DSN SD SAS DE CV.</span> recaba datos personales?</h3>
                    <p>
                        <span>En el caso de clientes y proveedores:</span><br />
                        Se recabarán únicamente los datos necesarios para la relación comercial con clientes y
                        proveedores con el fin de cumplir con el objeto de sus contratos y encargos.<br />

                        Datos necesarios para pagos, envío de paquetería, elaboración de contratos y envío de
                        información sobre los estudios públicos de la empresa.<br />

                        <span>En general:</span><br />
                        <span>DSN SD SAS DE CV.</span> con la finalidad de realizar investigaciones
                        de opinión pública y mercado.
                    </p>

                    <h3>¿Qué información vamos a recabar?</h3>
                    <span>En el caso de clientes y proveedores:</span>
                    <p>
                        Datos de identificación como nombre, domicilio, nacionalidad, teléfono, correo electrónico,
                        firma, etc.<br />
                        Datos bancarios como número de cuenta, CLABE interbancaria, número de sucursal, cuenta
                        de cheques y demás información que permita realizar transacciones bancarias.<br />
                        Datos fiscales como domicilio, Registro Federal de Contribuyentes, cédula de identificación
                        fiscal, constancia de inscripción en el Registro Federal de Contribuyentes y cualquier
                        documentación relacionada.<br />
                        Datos profesionales como ocupación, historial profesional, referencias personales,
                        certificaciones, trayectoria laboral y educativa, copia de cédula profesional.
                    </p>
                    <span>En general:</span>
                    <p>Puede requerir los siguientes datos:<br />
                        Rango de edad, sexo, escolaridad y rango de perfil socioeconómico. Estos datos serán
                        siempre solicitados de forma anónima, el entrevistado no proporcionará ningún dato que le
                        permita ser identificado y siempre será de manera telefónica a través de un sistema de
                        combinación de números aleatorio.
                    </p>
                    <h3>¿Para qué fines usamos su información?</h3>
                    <span>Clientes y proveedores:</span>
                    <p>
                        Los datos personales que nos ha proporcionado serán utilizados para las siguientes
                        actividades primarias y necesarias:
                    </p>
                    <ol type="a">
                        <li>Identificarle</li>
                        <li>Contactarle</li>
                        <li>Dar seguimiento, actualización y confirmación en cuanto a productos y servicios contratados</li>
                        <li>Proveerle del servicio que solicite</li>
                        <li>Mantener actualizados nuestros registros a fin de poder responderle a sus consultas</li>
                        <li>Evaluar la calidad de los servicios que le ofrecemos</li>
                        <li>Responder a sus comentarios o solicitudes de información.</li>
                    </ol>
                    <span>En general:</span>
                    <ul>
                        <li><span>DSN SD SAS DE CV.</span> con el fin de realizar estudios de
                            opinión y estudios de mercado.
                        </li>
                        <li>Para difundir nuestras encuestas de opinión pública</li>
                    </ul>
                    <h3>¿A qué terceros podemos transferir su información?</h3>
                    <p>
                        <span>DSN SD SAS DE CV.</span> para cumplir las finalidades necesarias
                        anteriormente descritas o aquellas exigidas legalmente por las autoridades competentes,
                        podrá transferir los datos personales a:

                        <ul>
                            <li>Autoridades laborales y/ o a otras autoridades competentes para dar cumplimiento a
                                disposiciones legales</li>
                            <li>Empresas matrices, subsidiarias y/o filiales</li>
                            <li>Instituciones bancarias para realizar pagos y</li>
                            <li>Autoridades competentes en los casos legalmente previstos.</li>
                        </ul>

                        Para el efecto, le informamos que el consentimiento para la transferencia de sus datos
                        personales no es requerido.
                    </p>
                    <h3>¿Cómo puede ejercer sus derechos ARCO y/o revocar su consentimiento?</h3>
                    <p>
                        Usted podrá ejercer cualquiera de los derechos de acceso, rectificación, cancelación u
                        oposición (en lo sucesivo “derechos Arco”), así como revocar su consentimiento para el
                        tratamiento de sus datos personales enviando un correo electrónico a la dirección:<br />
                        <span>contacto@disruptivaconsulting.com</span>
                        <br />
                        Su petición deberá ser realizada a través del formato de solicitud de derechos ARCO, el cual
                        debe ser solicitado a la dirección de correo electrónico antes señalada. Para que <span>DSN SD SAS DE CV.</span> pueda
                        darle seguimiento a su solicitud, usted
                        deberá acreditar correctamente su identidad para lo que es necesario que complete todos los
                        campos indicados en el formato y lo acompañe con copia de alguna de las identificaciones
                        oficiales vigentes que se señalan en el mismo. Para mayor información sobre los
                        procedimientos, requisitos y plazos para el ejercicio de sus derechos ARCO y/o revocación
                        del consentimiento contáctenos al mismo correo electrónico
                    </p>
                    <h3>¿Cómo puede limitar el uso y/o divulgación de sus datos?</h3>
                    <p>
                        Usted podrá limitar el uso o divulgación de sus datos personales enviando su solicitud a la
                        dirección: <span>contacto@disruptivaconsulting.com</span> <br />
                        En caso de que su Solicitud sea procedente se le registrará en el listado de exclusión de
                        <span>DSN SD SAS DE CV.</span> <br />
                        Para mayor información favor de contactar a la misma dirección electrónica.
                    </p>
                    <h3>¿Cómo le notificaremos de los cambios que efectuemos al presente Aviso de
                        Privacidad?
                    </h3>
                    <p>
                        DISRUPTIVA CONSULTING MEXICO le notificará de cualquier cambio a su aviso
                        de privacidad a través de nuestra página de internet: <span>http://www.disruptivaconsulting.com</span> por
                        lo cual le pedimos revise la misma de manera periódica.
                    </p>
                    <h3>¿Cómo puede hacernos llegar sus dudas?</h3>
                    <p>Estamos a sus órdenes para proporcionarle cualquier información adicional que requiera o en
                        su caso, para resolver cualquier duda que pudiera surgirle en materia de privacidad y
                        protección de datos personales, para lo que podrá contactarnos a través del correo
                        electrónico <span>contacto@disruptivaconsulting.com</span></p>
                </div>
            </PText>
        </div>
    )
}

export default Privacity;