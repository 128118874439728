import { Fragment } from "react";
import { Outlet } from "react-router-dom";

import PText from "../../components/ptext/ptext.component";
import ethics from '../../img/ethics.jpg'
import teamwork from '../../img/teamwork.png'
import confidential from '../../img/confidential.png'
import hardwork from '../../img/hardwork.png'
import responsability from '../../img/responsability.png'

import { AttentionSeeker, Fade, JackInTheBox, Slide, Zoom } from "react-awesome-reveal";

import './about.styles.scss'
import ImageAccordion from "../../components/image-accordion/image-accordion.component";

const categories = [
    {
        id: 1,
        title: 'Ética de Resultados',
        image: `${ethics}`,
    },
    {
        id: 2,
        title: 'Confidencialidad',
        image: `${confidential}`,
    },
    {
        id: 3,
        title: 'Responsabilidad',
        image: `${responsability}`,
    },
    {
        id: 4,
        title: 'Trabajo Duro',
        image: `${hardwork}`,
    },
    {
        id: 5,
        title: 'Trabajo en Equipo',
        image: `${teamwork}`,
    },
];



const About = () => {


    return (
        <Fragment>
            <section id="about">
                <div className="about-container">
                    <Slide triggerOnce>
                        {<PText>
                            <JackInTheBox duration={1500}><h1 className="about-topic">About Us</h1></JackInTheBox>
                            <span>DISRUPTIVA</span> somos un <span>Equipo Conformado por Profesionales Internacionales</span> de la <span>Consultoría
                                Política, Comunicación y Cabildeo</span> en el cual utilizamos una innovadora herramienta que nos permite realizar acciones 
                                de identificación, recopilación, análisis y difusión para medir la opinión pública sobre cualquier tema de dominio general 
                                de manera eficiente y rápida con el propósito de mejorar la toma de decisiones.

                            <br /><br />
                            Desarrollamos un <span>Sistema de Inteligencia Artificial</span> con el cual se pueden obtener 
                            las respuestas del público meta en el menor tiempo posible. Su importancia radica en el carácter cambiante del medio y elimina el sesgo 
                            que implica el factor humano, lo que nos permite anticipación estratégica de forma descriptiva, diagnostica y predictiva para obtener 
                            los resultados más precisos y confiables.
                            <br></br>
                            <br></br>
                        </PText>}
                    </Slide>

                    <div className="test-container">
                        <div className="left-side">
                            <div>
                                <AttentionSeeker effect={"rubberBand"} delay={500} duration={1500} damping={0.5}><h1 className="about-topic">Valores</h1></AttentionSeeker>
                                <Fade delay={1500} damping={0.25} triggerOnce><ImageAccordion images={categories} /></Fade>
                            </div>
                        </div>
                        <div className="right-side">
                            <Fade cascade delay={500} duration={1500} damping={0.5} triggerOnce>
                                <div>
                                    <AttentionSeeker effect="shakeX"><h1 className="about-topic">Mision</h1></AttentionSeeker>
                                    <PText>Asesorar y formar en estrategias a candidatos,
                                        gobernantes, estrategas electorales y jefes de campaña
                                        en procesos electorales y gobierno.
                                    </PText>
                                    <AttentionSeeker effect="shakeX"><h1 className="about-topic">Vision</h1></AttentionSeeker>
                                    <PText>Ser la firma de asesoría y educación política líder en
                                        habla hispana.
                                    </PText>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="about-policy">
                        <Fade triggerOnce>
                            <PText style={{background: 'black', color: 'white', opacity:'unset', width:'50%'}}>
                                <Zoom duration={1500} direction='down'>
                                    <h1 className="about-topic" style={{textShadow: "none" , color:"white", fontFamily: "'Arial Narrow Bold', sans-serif"}}>
                                        Política de Calidad
                                    </h1>
                                </Zoom>
                                <p style={{marginLeft:'5%', marginRight:'5%'}}>Nuestro equipo de alto rendimiento provee servicios de calidad que
                                    satisfacen las expectativas y necesidades de nuestros clientes; este resultado lo logramos a
                                    través de la innovación tecnológica, formación y capacitación constante de nuestros
                                    colaboradores, mediante la mejora continua de nuestros procesos y en el marco del
                                    cumplimiento de los requisitos legales aplicables.
                                </p>
                                <br></br>
                                <br></br>
                            </PText>
                        </Fade>
                    </div>
                    <br></br>
                    <br></br>
                </div>
            </section>
            <Outlet />
        </Fragment>
    )
}

export default About;