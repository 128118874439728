import { Outlet } from "react-router-dom";
import solutionsBack from '../../img/solutions.png'
import comms from '../../img/comms.png'
import crisis from '../../img/crisis.png'
import lobbying from '../../img/lobbying.png'
import AccordionMenu from "../../components/accordion-menu/accordion-menu.component";
import PText from "../../components/ptext/ptext.component";

import './services.styles.scss'

const solutions = [
    {
        id: 1,
        title: 'SOLUCIONES DE POLÍTICAS PÚBLICAS',
        content: "Diseñamos, Implementamos y Evaluamos políticas públicas al igual que desarrollamos y Gestionamos Plataformas Electorales y Planes de Gobierno así como brindamos la Capacitación necesaria para el servicio público.",
        url: `${solutionsBack}`,
    },
    {
        id: 2,
        title: 'COMUNICACIÓN POLÍTICA',
        content: 'Operamos bajo el principio de la Metodología Cuarteles War Room, la cual ' +
            'hace uso del Análisis (A), Estrategia (E) y Operaciones (O) políticas; enmarcados en la campaña permanente, es ' +
            'decir, el War Room opera en campaña y gobierno garantizando el éxito electoral y la gobernabilidad respectivamente.',
        url: `${comms}`,
        subcontent:
            `
            Gestión del Plan Estratégico de Comunicació /
            Diseño Publicitario y Producción de Contenidos /
            Gestión Mediática y Relación con Medios de Comunicación /
            Oratoria y Elaboración del Discurso Político /
            Media Training`,
    },
    {
        id: 3,
        title: 'GESTIÓN DE CRISIS',
        content: "Volcamos las capacidades de toda nuestra organización, " +
            'involucrando equipos multidisciplinares expertos, capaces de ayudar en la preparación, ' +
            'respuesta y recuperación ante las situaciones de crisis más complejas realizando las ' +
            'siguientes acciones:',
        url: `${crisis}`,
        subcontent:
            'Análisis de Riesgo /' +
            'Implementación y Operación de Salas de Situación / ' +
            'Negociación y Contención de Daños / ' +
            'Gestión del Plan Estratégico de la Comunicación / ' +
            'Auditoria de Información Forense',
    },
    {
        id: 4,
        title: 'NEGOCIACIÓN Y CABILDEO',
        content: "Desarrollamos la planeación conceptual y operativa realizando "+
        'una negociación estratégica que genere incidencia en la opinión pública, redes sociales y medios de '+
        'comunicación.',
        url: `${lobbying}`,
    },

];

const menu_services = [
    {
        id: 1,
        title: 'MENSAJES MASIVOS DE VOZ',
        content: 'Envío de mensajes de voz masivos a teléfonos fijo y ' +
            'móviles con el objetivo de difundir un mensaje específico de algún evento, producto o servicio.' +
            '\nSegmentación Demográfica Nacional, Estatal o Municipal.',
    },
    {
        id: 2,
        title: 'MENSAJES MASIVOS DE SMS',
        content: 'Envío de mensajes de texto masivos a teléfonos móvil de ' +
            'hasta 160 caracteres donde se pueden incluir ligas o textos. Segmentación Demográfica ' +
            'Nacional, Estatal o Municipal.',
    },
    {
        id: 3,
        title: 'ENCUESTAS TELEFÓNICAS IA',
        content: ' Encuestas telefónicas automáticas mediante la técnica de ' +
            '“robot” con grabaciones enviadas a teléfonos fijos o móviles en forma aleatoria, de tal manera ' +
            'que sean representativas de la muestra establecida.',
    },
    {
        id: 4,
        title: 'ENCUESTAS DE OPINIÓN',
        content: 'Método popular para recopilar información vital del mercado a ' +
            'través del cual podemos identificar sus necesidades y dirigirnos a un sector particular del ' +
            'público. El enfoque de encuesta telefónica proporciona tal vez el nivel más alto de anonimato ' +
            'para los encuestados que desean mantener sus opiniones confidenciales. Las encuestas ' +
            'anónimas facilitan la precisión en las respuestas, especialmente en temas controversiales.',
    },
    {
        id: 5,
        title: 'SOCIAL MEDIA ANALYTICS',
        content: 'Es el análisis de puntos de datos que te ayuda a medir el ' +
            'rendimiento de tus cuentas de redes sociales.\n' +
            '“Una cosa es saber en tu corazón que eres un genio de las redes ' +
            'sociales y otra muy diferente es poder demostrarlo.”\n' +
            'Es por eso que el análisis de las redes sociales es tan crucial para cualquier campaña o ' +
            'estrategia de marca exitosa. Los datos te mantienen encaminado para lograr tus objetivos de ' +
            'redes sociales, demostrando lo que funciona y, con igual importancia, lo que no.',
    },

]

const Services = () => {
    return (
        <section id="services" >
            <div className="services-container">
                <div className="service-test-right-container">
                    <PText>
                        <h1 className="services-title">NUESTRO SISTEMA IA DELTA/T 2.4</h1>
                        Es el conjunto de acciones realizadas para evaluar y consolidar su imagen o mensaje en la mente del 
                        público  meta; la sustentabilidad y la permanencia en cualquier terreno es el elemento central del 
                        éxito, por lo tanto, para lograr el objetivo realizamos un posicionamiento activo a través de las 
                        herramientas del Sistema de Inteligencia Artificial que hemos desarrollado.
                        <br/>
                        <br/>
                        <AccordionMenu items={menu_services} />                    
                    </PText>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <div className="services-test-container">
                    {solutions.map((item, index) => (
                        <div className="services-test-container-item"
                            key={item.id}
                            style={{ backgroundImage: `url(${item.url})`}}>
                            <div className='content'> {item.content}</div>
                            <div className='content'> {item.subcontent}</div>
                            <h1 className="content-title">{item.title}</h1>
                        </div>
                    ))}
                </div>
                <Outlet />
            </div >
        </section >

    )
}

export default Services;