import { HashLink } from "react-router-hash-link";
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/fa'
import logo from '../../img/logo-consulting-mx.png'
import './footer.styles.scss'
import { NavLink } from "react-router-dom";


const Footer = () => {
    return (
        <div>
            <div className="footer-container">
                <div className="copyright-container">
                    <p>Copyright © {new Date().getFullYear()}</p>
                    <p>DSN SD SAS DE CV</p>
                </div>
                <div className="sections-container"> Links
                    <ul className="links-container">
                        <li><HashLink to="#" smooth style={{textDecoration:'none', color:'white'}}>Home</HashLink></li>
                        <li><HashLink to="#about" smooth style={{textDecoration:'none', color:'white'}}>About</HashLink></li>
                        <li><HashLink to="#contact" smooth style={{textDecoration:'none', color:'white'}}>Contact</HashLink></li>
                        <li><NavLink to="privacity" style={{textDecoration:'none', color:'white'}}>Aviso de Privacidad</NavLink></li>
                    </ul>
                </div>
                <div className="socials-container">
                    <img src={logo} alt="logo" width='100' height='59.68'/>
                    <ul className="social-links-container">
                        <li><a href='https://www.facebook.com' target="_blank" rel="noreferrer" style={{color:'white'}}> <FaFacebook /> </a></li>
                        <li><a href='https://www.instagram.com/' target="_blank" rel="noreferrer" style={{color:'white'}}> <FaInstagram/> </a></li>
                        <li><a href='https://www.twitter.com' target="_blank" rel="noreferrer" style={{color:'white'}}> <FaTwitter /> </a></li>
                    </ul>
                </div>
            </div>
        </div>

    )
}

export default Footer;