import { AttentionSeeker, Fade, Rotate } from "react-awesome-reveal";
import { Outlet } from "react-router-dom";
import About from '../about/about.component'
import Contact from '../contact/contact.component'
import Services from "../services/services.component";

import './home.styles.scss'
const Home = () => {
    return (
        <section id="home">
            <div className="home-container">
                <div className="home-background">
                    <div className="home-info-container">
                        <Rotate direction="bottom-left">
                            <AttentionSeeker effect="rubberBand" delay={1800}>
                                <div>Consultoría</div>
                            </AttentionSeeker>
                        </Rotate>
                        <Fade delay={1000}>
                            <AttentionSeeker effect="rubberBand" delay={1800}>
                                <div>Campañas Electorales</div>
                            </AttentionSeeker>
                        </Fade>
                        <Rotate direction="top-left" >
                            <AttentionSeeker effect="rubberBand" delay={1800}>
                                <div>Smart Analytics</div>
                            </AttentionSeeker>
                        </Rotate>
                    </div>
                </div>
            </div>
            <About />
            <Services />
            <Contact />
            <Outlet />
        </section>
    )
};

export default Home;