import './ptext.styles.scss'

const PText = ({style, children}) => {
    return (
        <div className='pstyle' style={style}>
            <div>{children}</div>
        </div>
    )
}

export default PText;

